import {createStore} from 'vuex'
import axios from 'axios'
const strore = createStore({
    state:{
        curlist:[ ],
        childage:8,
        defcur:{id:'',sym:''},
        refperson:{id:0,login:false},
        cart:{id:null,det:{},rate:{} ,currency:'USD'},
        cartproc:false,
        curl:'',
    },
    getters:{
        defCurrency(state){
            return state.defcur;
        },
        refPerson(state){
            return state.refperson;
        },
        CartId(state){
            return state.cart.id;
        },
        CartData(state){
            return state.cart;
        },
        currencies(state){
            return state.curlist;
        },
        CartCurrencyID(state){
            if(state.cart.id)
                return state.cart.currency;
            return  state.defcur.id;
        },
        isCartProcessing(state){
            return state.cartproc;
        },
        Curl(state){
            return state.curl;
        }
    },
    mutations:{
        SET_CURL(state,uri){
            state.curl = uri;
        },
        SET_CURLIST(state,ilist){
            state.curlist = ilist;
        },
        SET_AGE(state,age){
            state.childage = age;
        },
        SET_DEFCUR(state,currency){
            state.defcur = currency;
        },
        SET_REFPERSON(state,rp){
            state.refperson = rp;
        },
        SET_CART(state,cart){
            if(cart.id)
                state.cart = cart;
            else
                state.cart = {id:null};
        },
        SET_CART_PROC(state,sts){
            state.cartproc = sts;
        }
    },
    actions:{
        assirgCurrencis({commit},clist){
            commit('SET_CURLIST',clist);
            if(clist.length>0){
               commit('SET_DEFCUR',clist[0])
            }
        },
        findRefPerson({commit}, input){
            input.email = this.state.refperson.email ;
            axios.get(  this.state.curl+'/bapi/search/refp' ,{params: input })
                .then(response => {
                    if(response.data.status==0){
                        commit('SET_REFPERSON', response.data.refp);
                    }
                })
                .catch(error => {
                    console.log('error ',error.toString() );
                    //this.warns.srv =error.toString();
                })
                .then(() => {
                    commit('SET_CART_PROC',false);
                });

        },
        initDefCurrency({commit},cID){
            let tcurr = this.state.curlist.find(({id})=>id === cID);
            if(tcurr)
                commit('SET_DEFCUR',tcurr);
        },

        addCartSrv({commit}, input){
            commit('SET_CART_PROC',true);
            axios.get(  this.state.curl+'/bapi/add/srv' ,{params: input})
                .then(response => {
                    if(response.data.status>0){
                        console.log('error ', error.toString()) ;
                        //this.warns.srv =error.toString();
                    } else {
                        commit('SET_CART', response.data.cart )
                    }
                })
                .catch(error => {
                    console.log('error ',error.toString() );
                    //this.warns.srv =error.toString();
                })
                .then(() => {
                    // always executed
                    commit('SET_CART_PROC',false);
                });
        },
        chCartItem({commit}, input){
            commit('SET_CART_PROC',true);
            let apiurl = this.state.curl+'/bapi/del/room';
            if(input.srvid)
                apiurl = this.state.curl+'/bapi/del/srv';
            
            axios.get(  apiurl ,{params: input})
                .then(response => {
                    if(response.data.status>0){
                        console.log('error ', error.toString()) ;
                        //this.warns.srv =error.toString();
                    } else {
                        commit('SET_CART', response.data.cart );
                    }
                })
                .catch(error => {
                    console.log('error ',error.toString() );
                    //this.warns.srv =error.toString();
                })
                .then(() => {
                    // always executed
                    commit('SET_CART_PROC',false);
                });

        },
        resetCart({commit}, input){
            commit('SET_CART',{id:null});
            axios.get(this.state.curl+'/bapi/cart/clear',{params:input})
                .then(response => {
                    if(response.data.status>0){
                        console.log('response ', response.data.message );
                    }
                })
                .catch(error => {
                    console.log('connection error', error.toString());

                })
                .then(() => {
                  console.log('cart cleared');

                });
        },
        getCartData({commit}, input){
            commit('SET_CART_PROC',true);
            axios.get(  this.state.curl+'/bapi/cart/get', {params:  input })
                .then(response => {
                    if(response.data.status>0){
                        console.log('error ', error.toString()) ;
                    } else {
                        commit('SET_CART', response.data.cart )
                    }

                })
                .catch(error => {
                    console.log('connection error', error);
                })
                .then(() => {
                    commit('SET_CART_PROC',false);
                });

        }

    },
});

export default strore;