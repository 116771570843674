<template>
  <div class="m-loader" v-if="sending">Loading...</div>
  <div v-else class="m-cart-content" >
    <div class="m-loader" v-if="srvloading">Loading...</div>
    <div v-if="foundNOTempty" style="display: flex; flex-direction: column; gap: 4px; padding: .5em 2em; margin-bottom: 1em; ">
      <div class="m-cart-srv-item m-border-radius " v-for="s in srvfound" ref="s.id">
        <div style="flex: 2 0 20em; display: flex; align-items: center; font-size: larger;">{{s.title}}  </div>

        <div v-if="s.setq" class="m-btn-group" style="flex: 1 0 8em;">
          <button  v-if="s.q>1" class="m-ps-button m-text-center"  style="width: 2em;" @click="s.q-=1" ><span class="m-ico"> ➖ </span></button>
          <span class="m-ps-button m-text-center" style="width: 2em;">{{s.q}}</span>
          <button class="m-ps-button m-text-center"  style="width: 2em;" @click="s.q+=1" ><span class="m-ico"> ➕ </span></button>
        </div>
        <div v-else style="flex: 1 0 8em;"> </div>

        <div style="flex: 1 0 8em; " >

          <mdrop-down v-if=" s.showdays" style="flex-grow: 1;" :items="s.price.days" :btnlabel="showprice(s.price.promo,s.q)"
                      btnclass="m-form-drop m-text-end m-border-radius " >
            <template v-slot:dropdownitem="lpd">
              <div class="m-btn-vlist">
                <div>{{lpd.item.md}} {{lpd.item.dow}}</div>
                <div>{{lpd.item.price.toFixed(2)}} {{ cart.currency_sym }}</div>
              </div>
            </template>
          </mdrop-down>
          <div v-else class="m-form-label m-border-radius m-text-end">
            {{showprice(s.price.promo,s.q)}}
          </div>
        </div>
        <div   >
        <button   class="m-button m-border-radius" @click="addsrv(s.rid,s.id,s.q)"
                :title="i18n.add2cart"> <span class="m-ico">&#128722;</span></button>
      </div>
      </div>

      <button class="m-button m-border-radius" @click="srvfound=[]">{{i18n.close}}</button>
    </div>
    <div class="m-cart-title">
      <img :title="i18n.htitle" class="m-logo" :src="i18n.hlogo" :alt="i18n.htitle" />
      <h2 >Детали бронирования</h2>
      <mdrop-down  :btntit="i18n.showdet" :msize="parseInt(380)"  :items="cart.vat.list" style="font-size: larger; "
                   :btnlabel="i18n.sumtot+` `+showprice( cart.vat.total,1) "  btnclass="m-btn-vlist m-border-radius"        >
        <template v-slot:dropdownitem="lpd">
          <div class="m-btn-vlist">
            <div>{{lpd.item.title}} </div>
            <div> {{lpd.item.sum.toFixed(2)}} {{cart.currency_sym}}</div>
          </div>
        </template>
      </mdrop-down>
    </div>
    <div class="m-warn-block" v-if="warns.master"> {{ warns.master }}</div>
      <div class="m-form-bar">
        <div style="display: flex; flex: 1 1 262px; height: 100%; " >
          <span class="m-ps-button m-text-center" style="width: 100%"> {{i18n.chin}} : {{ formatteddate(cart.dfrom) }} </span>
        </div>
        <div style="display: flex; flex: 1 1 262px; height: 100%; " >

          <span class="m-ps-button m-text-center" style="width: 100%;">{{i18n.chout}} : {{ formatteddate(cart.dtill) }}</span>
        </div>
        <div style="display: flex; flex: 1 1 262px; height: 100%; " >
          <span class="m-ps-button m-text-center" style="width: 100%">{{ i18n.nights}} :  {{ cart.det.nights }}
           [ {{cart.rate.mealtxt }} ]
          </span>
        </div>
      </div>

      <div class="m-found-list">
        <div class="m-cart-item"  v-for="(ru,ridx) in cart.rlist" :key="ru.id">
          <div class="m-cart-title" >
            <div v-if="cartprocessing" class="m-loading "> </div>
            <mdrop-down v-else @m-confirm="remove(ru.id,0,0)" mtxt="remove from cart" items="" style="--m-txt-align:center;"
                        btnlabel='<span class="m-ico">❌</span>' btnclass="m-border-radius m-btn-vlist" :msize="parseInt(300)" >
            </mdrop-down>
            <h3 style="flex-grow: 4; justify-content: center;">{{ridx+1}} :  {{ ru.title }}</h3>

            <mdrop-down  :btntit="i18n.showdet" :msize="parseInt(380)"  :items="ru.prices.dayprices" style="flex-grow: 1;"
                           :btnlabel="showprice(ru.prices.promo,1)"  btnclass="m-form-drop m-border-radius"        >
                <template v-slot:dropdownitem="lpd">
                  <div class="m-btn-vlist">
                    <div>{{lpd.item.md}} {{lpd.item.dow}}</div>
                    <div>{{lpd.item.promo.toFixed(2)}} {{ cart.currency_sym }}</div>
                  </div>
                </template>
              </mdrop-down>
          </div>

          <div class="m-cart-room-data">
            <div class="m-cart-srv-list">
              <div v-for="bed in ru.cap2" :key="bed.p" style="display: flex; justify-content: space-between; margin: 0 2em;" >
                <div>
                  <img :src="bed.img" style="height: 32px; margin-right: 1em;" :title="bed.cap" />{{bed.cap }}
                </div>
                <div><b> &times; {{ bed.n }}</b></div>
              </div>
            </div>
            <div class="m-cart-room-guests">
              <h5 style="text-align: center;">{{i18n.gstnames}}</h5>
              <div class="m-cart-guest-names" v-for="(g,idx) in ru.guests" :key="idx"  >
                <div class="m-form-label m-border-radius" style="width: 2em;">{{idx}}</div>
                <input class="m-form-control" v-model="g.fn"  :placeholder="i18n.fname">
                <input class="m-form-control" v-model="g.sn" :placeholder="i18n.sname">
              </div>
            </div>
          </div>

          <div>
            <div class="m-cart-title"  >
              <h5 style="flex-grow: 4;" >{{i18n.srvlist}}</h5>
              <div v-if="cartprocessing"   > </div>
              <div v-else >
                <button class="m-border-radius m-button" @click="getsrvlist(ru.id )"><span class="m-ico">&#128722;</span> {{i18n.addsrv}}</button>
              </div>
            </div>
            <div style="display: flex; gap:2px; flex-direction: column; ">
              <div class="m-cart-srv-item m-border-radius " v-for="s in  ru.srvlist" ref="s.id">
                <div style="flex: 4 0 444px; display: flex; align-items: center; ">
                <span  style="width: 100%;font-size: larger;"> {{s.q}} &times; {{s.title}}  </span>
                </div>
                <div style="flex: 1 0 120px; " >
                    <mdrop-down v-if="s.prices.showdays" style="flex-grow: 1;" :items="s.prices.days" :btnlabel="showprice(s.promo,1)"
                                 btnclass="m-form-drop m-text-end m-border-radius " >
                      <template v-slot:dropdownitem="lpd">
                        <div class="m-btn-vlist">
                          <div>{{lpd.item.md}} {{lpd.item.dow}}</div>
                          <div>{{lpd.item.price.toFixed(2)}} {{ cart.currency_sym }}</div>
                        </div>
                      </template>
                    </mdrop-down>
                    <div v-else class="m-form-label m-border-radius m-text-end">
                      {{showprice(s.promo,1)}}
                     </div>
                </div>
                <div v-if="cartprocessing" class="m-loading "> </div>
                <div v-else  style="flex: 1 0 88px; display: flex; gap: 4px; justify-content: end;"  >
                  <button v-if="s.setq" class="m-button m-text-center m-border-radius"
                          @click="remove(ru.id,s.id,parseInt(s.q)+1)" ><span class="m-ico"> ➕ </span></button>
                  <div v-if="s.q>1" style="width: 100%" >
                    <button  class="m-button m-text-center m-border-radius"
                            @click="remove(ru.id,s.id,parseInt(s.q)-1)" ><span class="m-ico"> ➖ </span></button>
                  </div>

                  <mdrop-down v-else @m-confirm="remove(ru.id,s.id,0)" mtxt="remove from cart" items="" style="--m-txt-align:center; width: 100%;"
                              btnlabel='<span class="m-ico">❌</span>' btnclass="m-button m-border-radius" :msize="parseInt(300)" >
                  </mdrop-down>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

<div class="m-cart-footer m-border-radius ">
<div class="m-cart-room-data">
<div>  <h4 class="m-text-center">{{i18n.refperson}}</h4></div>

            <div class="m-btn-group">
              <label class="m-checkbox">
                <input class="m-checkbox__input"  type="checkbox" v-model="polconf"  >
                <span class="m-checkbox__ico"></span>
              </label>
              <modal-dialog :icontent="i18n.hpolicy" :ititle="i18n.guarnty" :ibtnlabel="i18n.bpolicy" ibtncls="m-btn-vlist m-border-radius" > </modal-dialog>
          </div>

          <div>
            <div class="m-cart-guest-names">
              <div class="m-form-label m-border-radius" style="width: 40%;" >e-mail</div>
              <input ref="rpmail" class="m-form-control" type="email" @change="findGuest"  placeholder="email" v-model="refperson.email">
            </div>

            <div class="m-cart-guest-names ">
              <div class="m-form-label m-border-radius" style="width: 40%;" >tel</div>
              <input ref="rptel" class="m-form-control" type="tel"  placeholder="phone number" v-model="refperson.tel">
            </div>

          </div>
          <div>
            <div class="m-cart-guest-names"  >
              <div class="m-form-label m-border-radius" style="width: 40%;" >{{i18n.fname}} </div>
              <input ref="rpfname" class="m-form-control" type="text"  :placeholder="i18n.fname" v-model="refperson.fname">
            </div>

            <div class="m-cart-guest-names" >
              <div class="m-form-label m-border-radius" style="width: 40%" >{{i18n.sname}} </div>
              <input ref="rpsname" class="m-form-control" type="text"  :placeholder="i18n.sname" v-model="refperson.sname">
            </div>

          </div>
          <div class="m-warn-block" v-if="warns.refp" @click="warns.refp=null">{{warns.refp}}</div>
        </div>
  <div v-if="cartprocessing" style="display: flex;  margin: 1em 0; height: 2em; justify-content: center; ">
    <div  class="m-loading  "> </div>
  </div>
<div v-else style="display: flex; margin: 1em 0; gap:10px; flex-wrap: wrap; justify-content: center;">
  <div>
    <button v-if="polconf" class="m-button m-border-radius" @click="proceed"><span class="m-ico"> 🛒 </span> {{i18n.bproceed}}</button>
    <button v-else class="m-button m-border-radius" disabled > <span class="m-ico"> 🛒 </span> {{i18n.bproceed}}</button>
  </div>

  <mdrop-down style="--m-txt-align:center;" items=""  btnclass="m-button m-border-radius" msize="300"
              :btnlabel="i18n.clear" :mtxt="i18n.clear" @m-confirm="reset"></mdrop-down>
  </div>
</div>

  </div>
</template>
<script>
    import axios from 'axios'
    import ModalDialog from './ModalDialog'
    import MdropDown from './MdropDown'
    import moment from 'moment'

    export default {
        name: 'BookingCart',
        components: { MdropDown, ModalDialog },
        props: [ 'curl','hid','i18n','lang','cart' ],
        data: function () {
            return {
                sending:false,
                srvloading:false,
                warns:{},
//                refperson:  this.refp,
                polconf:false,
                srvfound:[],

            }
        },
        emits: ['cart-proceed','cart-updating'],
        methods: {
            formatteddate(cday){
                if(this.lang)                  moment.locale(this.lang);
                let tday = moment(cday);
                return  tday.format('ddd LL');
            },
            proceed(){
                this.warns={};
                //console.log('ref person ', this.$refs.rpmail.validity.valid );
                if( !this.refperson.email || !this.$refs.rpmail.validity.valid ) {
                    this.$refs.rpmail.focus();
                    this.warns.refp = this.i18n.mwarn ;
                    return  false
                }
                if( !this.refperson.tel || !this.$refs.rptel.validity.valid ) {
                    this.$refs.rptel.focus();
                    this.warns.refp = this.i18n.mwarn ;
                    return  false
                }
                if( !this.refperson.fname || this.refperson.fname.length < 2)
                {
                    this.$refs.rpfname.focus();
                    this.warns.refp = this.i18n.mwarn ;
                    return  false
                }
                if( !this.refperson.sname || this.refperson.sname.length < 2)
                {
                    this.$refs.rpsname.focus();
                    this.warns.refp = this.i18n.mwarn ;
                    return false;
                }
                this.checkPhone();
            },
            reset(){
                window.scrollTo({ top: 0, behavior: 'smooth' });
                this.$store.dispatch("resetCart",{
                    hid: this.hid,
                    session:localStorage.getItem('session'),
                    cartid: this.cart.id ,
                } );
                document.getElementById('booker3a').scrollIntoView();
            },
            remove(roomid,srvid,q){
                this.warns = {};
                let data = {
                    hid: this.hid,
                    currency:this.$store.getters.defCurrency.id,
                    cartid: this.cart.id,
                    session:localStorage.getItem('session'),
                    rid:roomid,
                    srvid:srvid,
                    q:q,
                } ;
                this.$store.dispatch("chCartItem",data);
                this.$emit('cart-updating');
            },
            getsrvlist(roomid){
                let curl  = this.curl +'/bapi/search/srv',
                    data = {
                        cartid: this.cart.id,
                        room: roomid,
                        hid: this.hid,
                        currency:  this.$store.getters.defCurrency.id ,
                        session:localStorage.getItem('session'),
                    };
                //console.log('get services fro room ', curl  );
                this.warns = {};
                this.srvfound = [];
                this.srvloading = true;
                this.$emit('cart-updating');
                axios.get(curl, {params: data})
                    .then(response => {
                        // console.log('Status 2 ', response.data.status);
                        if(response.data.status>0){
                           this.warns.srv = response.data.message;
                        } else {
                            this.srvfound = response.data.list

                        }

                    })
                    .catch(error => {
                        console.log('connection error', error.toString());
                        this.warns.srv =error.toString();
                    })
                    .then(() => {
                        this.srvloading = false;
                        document.getElementById('booker3a').scrollIntoView();
                    });

            },
            showprice(txt,q){
                if(this.cartprocessing) return  '  ⏳  ';
                let mval = parseFloat(txt) ;
                if(q)  mval = mval*q;
                return mval.toFixed(2)+' '+this.cart.currency_sym;
            },
            addsrv(room,srv,q){
                let data = {
                    cartid: this.cart.id,
                    room: room,
                    srv: srv,
                    q:q,
                    currency: this.cart.currency,
                    session:localStorage.getItem('session'),
                };
                this.srvfound=[]; this.warns = {};
                this.$store.dispatch("addCartSrv",data);
                this.$emit('cart-updating');
            },
            findGuest(){
                if( this.refperson.email && this.$refs.rpmail.validity.valid ) {
                    this.$store.dispatch("findRefPerson",{ session:localStorage.getItem('session'),  hid: this.hid,});
                }
            },
            checkPhone(){
                this.warns={};
                this.$store.commit('SET_CART_PROC',true);
                let curl  = this.curl +'/bapi/search/tel', data = {
                            cartid: this.cart.id,
                            hid: this.hid,
                            session:localStorage.getItem('session'),
                            tel: this.refperson.tel
                        };

                axios.get(curl, {params: data})
                    .then(response => {
                        if(response.data.status>0){
                            this.warns.refp = response.data.message ;
                            this.$refs.rptel.focus();
                        } else {
                            this.sending = true;
                            this.$emit('cart-proceed',  this.refperson );
                        }
                        this.$store.commit('SET_CART_PROC',false);
                    })
                    .catch(error => {
                        console.log('connection error', error.toString());
                        this.warns.master =error.toString();
                        this.$store.commit('SET_CART_PROC',false);
                    });
            }
        },
        computed:{
            cartID(){
                return  this.$store.getters.CartId ;
            },
            cartprocessing(){
                return this.$store.getters.isCartProcessing ;
            },
            refperson(){
                return this.$store.getters.refPerson ;
            },
            foundNOTempty(){
               return !(this.srvloading || this.srvfound.length<1);
            }

        }
    }
</script>
<style>
  .m-form-label {
    line-height: 1.5;
    display: block;
    font-size: larger;
    padding: .2em .4em;
    text-align: center;
    background-color: var(--m-color-light,white);
  }
  .m-form-drop {
    line-height: 1.5;
    display: block;
    font-size: larger;
    padding: .2em .4em;
    text-align: center;
    background-color: var(--m-color-light,white);
    width: 100%;

    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),linear-gradient(to bottom, var(--m-color-bg1,#b3b3b3), var(--m-color-light,white));
    background-repeat: no-repeat;
    background-position: left 0.3em center, left top;
    background-size: 16px 12px, 100%;

  }
  .m-form-drop:hover{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),linear-gradient(to top, var(--m-color-bg1,#b3b3b3), var(--m-color-light,white));
  }

  .m-cart-guest-names {
  display: flex;
  margin-bottom: .5em;
  gap: 2px;
}
.m-form-control {
  line-height: 1.5;
  display: block;
  width: 100%;
  border: 1px solid var(--m-color-border,cornsilk);
  border-radius: var(--m-border-rad,.35em);
  padding: .2em .4em;
}
  .m-cart-content {
    background-color: var(--m-color-bg1, white);
    padding: 0 1em;
    margin: 1em auto 0 ;
    border: 1px solid var(--m-color-success, darkgreen);
    border-radius: var(--m-border-rad,.35rem);
  }
.m-cart-item {
  padding: 4px .4em;
  box-shadow: 4px 2px 4px var(--m-color-bg2, silver);
  border-radius: var(--m-border-rad,.5em);
  border: 1px solid var(--m-color-border,cornsilk);
  background-color: var(--m-color-light,white);
}
  .m-cart-title {
    display: flex;
    justify-content: space-between;
    margin: .4em .2em 1em .2em;
    flex-wrap: wrap;

  }
  .m-cart-title h1,h2,h3,h4,h5 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .m-cart-room-data {
    display: flex;
    flex-wrap: wrap;
  }
  .m-cart-room-data > div {
    min-width: 50%;
    flex-grow: 1;
    padding: 2px .2em;
  }
.m-logo {
  width: 64px;
  border: 2px solid var(--m-color-border,silver);
  padding: 1px;
}
.m-logo:hover {
  transform: scale(1.25);
}
  .m-cart-footer {
    padding: 4px .4em;
    margin: .4em 0;
    box-shadow: 4px 2px 4px var(--m-color-bg2, silver);
    background-color: var(--m-color-light,white);
  }
.m-cart-srv-item {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  padding: .2em .8em;
  background-color: var(--m-color-light,white);
}


  /* button loading */
  .m-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 3em;
    line-height: 1.5;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    white-space: nowrap;
  }
  .m-loading::after {
    content: '';
    width: 38px;
    height:38px;
    border: 8px solid var(--m-color-border, silver);
    border-bottom-color: var(--m-color-info, #bf00ff ) ;

    border-radius: 50%;
    animation:  m-loading-timer 1s linear infinite;
    opacity: .75;
  }
  @keyframes m-loading-timer {
    to {
      transform: rotate(1turn);
    }
  }
</style>