<template>
<div class="m-form-bar">
  <div style="display: flex; flex: 1 1 202px;   " >
    <span class="m-ps-button m-ico m-border-radius" :title="i18n.chin"  >📅</span>
    <date-picker mname="dfrom" ref="mdf" :lang="lang" :mflim="fromdatelim" :mstart="dfrom" @md-selected="setdfrom" :mreadonly="cartexists" ></date-picker>
  </div>
  <div style="display: flex; flex: 1 1 202px;   ">
    <span class="m-ps-button m-ico m-border-radius" :title="i18n.chout"  >📅</span>
    <date-picker mname="dtill" ref="mdt" :lang="lang" :mflim="dfrom" :mstart="dtill" @md-selected="setdtill" :mreadonly="cartexists"></date-picker>
  </div>

<div style="display: flex;  flex: 1 1 0;  ">
  <label  class="m-ps-button m-border-start" style=" width: 100%; ">{{i18n.adults}}</label>
  <mdrop-down   :items="adultslist" :btnlabel="adults" msize="100" btnclass="m-drop-select m-border-end" >
    <template v-slot:dropdownitem="lpd">
      <button type="button" class="m-btn-vlist" style="font-size: larger; justify-content: center;" @click="adults=lpd.item">
        <strong>{{ lpd.item }}</strong>
      </button>
    </template>
  </mdrop-down>
</div>

  <child-age :i18n="i18n" :lang="lang" @m-set-child="setages" ></child-age>

  <div style="display: flex; flex: 1 1 100px;   ">
    <button type="button" class="m-button m-border-radius" @click="searchRoom" ><span class="m-ico" >&#128269;</span> <b>{{i18n.btnsearch}}</b></button>
  </div>
  <div style="display: flex; flex: 0 1 0; gap: 2px;   ">
    <button type="button" class="m-drop-select m-border-radius"><span class="m-ico" >&#127873;</span></button>
<mdrop-down :items="currencies" :btnlabel="$store.getters.defCurrency.sym" msize="282" btnclass="m-drop-select m-border-radius" >
  <template v-slot:dropdownitem="lpd">
    <button type="button" class="m-btn-vlist" style="font-size: larger;" @click="changecurrency(lpd.item.id,lpd.item.sym)">
      <b>{{lpd.item.id }}</b> {{lpd.item.title}}
    </button>
  </template>
</mdrop-down>
    <button type="button" class="m-button m-border-radius" @click="guestprof"><span class="m-ico"  > 🔐 </span></button>
  </div>
</div>

  <div class="m-loader" v-if="sending">Loading...</div>
  <div v-else>
    <div class="m-warn-block" v-if="warns.master"> {{ warns.master }}</div>
    <div v-if="foundNOTempty"  class="m-found-list" >
      <div v-for="r in rfound" :key="r.id" class="m-room-list-item">

        <modal-slider :ilist="r.media" :title="r.detail[4].data"></modal-slider>

        <div class="m-room-info" >
          <h4> {{ i18n.requip }}  </h4>
          <div v-for="bed in r.cap2" :key="bed.p" style="display: flex; justify-content: space-between; margin: 0 1em;" >
            <div>
              <img :src="bed.img" style="height: 32px; margin-right: 1em;" :title="bed.cap" />{{bed.cap }}
            </div>
            <div><b>{{ bed.n }}</b></div>
          </div>

          <div style="display: flex; justify-content: space-between; margin: .5em  ;">
            <div><span class="m-ico">🔲</span> {{r.detail[6].name}} : </div>
            <div> {{ r.detail[6].data }} m<sup>2</sup></div>
            <div> {{ r.detail[7].data }} ft<sup>2</sup></div>
          </div>

          <ul class="m-equip-list">
            <li v-for="req in r.equip" :key="req.id">
{{req.title}}
            </li>
          </ul>
        </div>
        <div class="m-room-prices" >
          <div class="m-price-item" v-for="rate in r.prices" :key="rate.id">
            <button class="m-btn-vlist" @click="showRateDet(rate.id)" > {{rate.mealtxt}} </button>
            <button class="m-btn-vlist" @click="showRateDet(rate.id)" > {{rate.paymode}} </button>
            <mdrop-down :btntit="i18n.showdet" :msize="parseInt(380)"
                       :items="rate.dayprices" :btnlabel="rate.mean.toFixed(2)+` `+ currency.sym +` `+i18n.pernight"
                       btnclass="m-btn-vlist "  >
              <template v-slot:dropdownitem="lpd">
                <div class="m-btn-vlist">
                  <div>{{lpd.item.md}} {{lpd.item.dow}}</div>
                  <div>{{lpd.item.promo.toFixed(2)}} {{ currency.sym }}</div>
                </div>
              </template>
            </mdrop-down>
            <button type="button" class="m-btn-vlist" :title="i18n.add2cart" @click="addRoom(rate,r.id)">
              <span>{{rate.promo.toFixed(2)}} {{rate.currency}}</span> <span class="m-ico">&#128722;</span>
            </button>
          </div>
        </div>
      </div>
      <div style="margin: .2em 2em;" v-show="foundNOTempty" >
        <button type="button" class="m-button m-border-radius" @click="rfound=[]">{{i18n.close}}</button>
      </div>
    </div>
  </div>

  <modal-dialog ref="ratedet" :ibtnshow="false" :extraload="true"  ></modal-dialog>

</template>
<script>
  import axios from 'axios'
  import moment from 'moment'
  import DatePicker from './DatePicker'
  import ChildAge from './ChildAge'
  import MdropDown from './MdropDown'
  import ModalDialog from './ModalDialog'
  import ModalDiv from './ModalDiv'
  import ModalSlider from './ModalSlider'

    export default {
        name: 'SearchForm',
        components: {ModalDiv, DatePicker,ChildAge, MdropDown, ModalDialog, ModalSlider },
        props: ['lang','i18n','curl','hid','room' ],
        data: function () {
            return {
                adults: 1,
                ages:[],
                adultslist:[1,2,3,4,5],
                dfrom:null,
                dtill:null,
                fromdatelim:null,

                cat:0,
                sending:false,
                rfound:[],

                warns:{},

            }
        },

        methods: {
            showRateDet(rateid){
                this.$refs.ratedet.mshowModal(this.curl+'/bapi/getinfo/rate',{id:rateid});
            },
            setages(ages){
                this.ages = ages;
            },
            changecurrency(cid,csym){
                this.$store.commit('SET_DEFCUR',{id:cid,sym:csym} );
                localStorage.setItem('currency',cid );
                if( this.rfound.length>0 )                    this.searchRoom();

                if(this.cartexists)
                {
                    //console.log('Change currency : cart exists');
                    this.$store.dispatch("getCartData",{
                        hid: this.hid,
                        session:localStorage.getItem('session'),
                        currency:cid,
                    });
                }
            },
            setdfrom(mday) {
                this.dfrom = mday;
                let df = moment(this.dfrom), dt = moment(this.dtill);
                if (df >= dt) {
                    this.dtill = df.add(1,'days').format('YYYY-MM-DD');
                }
            },
            setdtill(mday){
                this.dtill = mday;

            },
            searchRoom(){
                document.getElementById('booker3a').scrollIntoView();
                let crate2=0;
                if( this.cartexists )
                {
                  crate2 =  this.$store.getters.CartData.det.rate
                } //!! заменить

                let data = {
                    lang:this.lang,
                    dfrom: this.dfrom,
                    dtill: this.dtill,
                    adult: this.adults,
                    child: this.ages,
                    cat: this.room,
                    rate:crate2 ,
                    hid: this.hid ,
                    currency:  this.$store.getters.defCurrency.id ,
                    session:localStorage.getItem('session'),

                };
                //console.log('search form ', data, this.curl );
                this.rfound=[];
                this.sending = true;
                this.warns = {};

                axios.get(this.curl+'/bapi/search/room', {params:  data })
                    .then(response => {
                        if(response.data.status>0){
                            this.warns.master = response.data.message;
                            this.rfound=[];
                        } else {
                            this.rfound = response.data.rooms ;
                        }

                    })
                    .catch(error => {
                        this.warns.master = error.toString();
                    })
                    .then(() => {
                        // always executed
                        this.sending = false;

                    });
            },
            addRoom(rate,catid){
                let curl = this.curl+'/bapi/add/room',
                    data = {
                        cartid: this.$store.getters.CartId,
                        dfrom: this.dfrom,
                        dtill: this.dtill,
                        party:rate.party,
                        rate: rate.id,
                        room: catid,
                        currency: this.$store.getters.defCurrency.id,
                        session:localStorage.getItem('session'),
                    };
               // console.log('send request add room ', curl,data.cartid );
                //document.getElementById('booker3a').scrollIntoView();
                this.sending = true;
                if(this.cartexists){

                    this.$store.commit('SET_CART_PROC',true);

                }
                axios.post(curl,data)
                    .then(response => {
                        if(response.data.status>0){
                            this.warns.master = response.data.message;
                        } else
                        {
                            this.$store.commit('SET_CART',response.data.cart);
                            this.rfound=[];
                        }

                    })
                    .catch(error => {
                        this.warns.master = error.toString();
                    })
                    .then(() => {
                        this.sending = false;
                        this.$store.commit('SET_CART_PROC',false);
                    });

            },
            guestprof(){
                console.log('open guest profile');
              //  this.$store.commit('SET_REFPERSON',{id:12,email:'email@dot.com',tel:'+380681233322',fname:'Степан',sname:'Сидоренко'});

            },
            getTXTclass(code){
                if(code>0)
                    return 'm-txt-warn';
                return 'm-txt-info';
            },

        },
        computed:{
            cartexists(){
              return  this.$store.getters.CartId ;
            },
            currencies(){
                return this.$store.getters.currencies;
            },
            foundNOTempty(){
                return ( this.rfound.length>0  );
            },
            currency(){
                return  this.$store.getters.defCurrency;
            }

        },
        created(){
            // инициализация календаря
            let td = moment();
            this.dfrom = td.format('YYYY-MM-DD');
            this.fromdatelim = td.format('YYYY-MM-DD');
            td.add(3,'days');

            this.dtill = td.format('YYYY-MM-DD');
        },

    }
</script>
<style>

</style>