<template>
  <button v-if="ibtnshow" type="button" :class="ibtncls" @click="mshowModal">{{ibtnlabel}}</button>
  <div class="m-dialog__outer" ref="modal2div" >
    <div  class="m-dialog__inner m-modal__size-m"  >
      <h4 class="m-dialog__title"><span> title </span><span @click="mClose" > 🗙 </span></h4>

      <div class="m-dialog__content " v-if="sending">
        <div class="m-loader"></div>
      </div>
      <div class="m-dialog__content " v-else>
content

        <div v-show="warnings.master" class="  m-warn" v-html="warnings.master">  </div>
      </div>
      <div class="m-dialog__footer">
        <button type="button" v-show="!sending" class="m-btn__dismiss" @click="mClose">close</button>
        <button type="button" v-show="!sending" class="m-btn__confirm" @click="savedata"  > confirm </button>

      </div>
    </div>
  </div>
</template>
<script>

    export default {
        name: 'ModalDiv',
        props: {
            ibtnshow: {type:Boolean,required:false,default:true},
            ibtncls: {type:String,required:false,default: 'm-btn-vlist'},
            ibtnlabel:{type:String,required:false,default:'open modal'},
        },
        emits:['success'],
        components: {

        },
        data: function () {
            return {
                sending:false,
                warnings:{},
            }
        },
        methods: {
            mshowModal(){
                this.$refs.modal2div.style.display = 'block';
            },
            mClose() {
                this.$refs.modal2div.style.display = null;
            },
            savedata(){
                console.log('emit success');
                this.$emit('success' );
                this.mClose();
            }
        }
    }

</script>
<style>

</style>
