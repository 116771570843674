<template>
  <div class="m2dropdown" style="display: flex;"  >
    <div class="m-ps-button m-border-start" @click="dropdowntoggle" > {{ i18n.children }} </div>
    <div >
      <button class="mform-select m-border-end" ref="mdbtn" type="button"  @click="dropdowntoggle" >
        <span > {{chcounr}} </span>
      </button>
    </div>
    <div v-show="isvisible" :class="extraclass"   >
      <div class="m2drop-menu"  :style="mstyle"  >
        <div class="m-input-grp" v-for="ipt in ageforms" :key="ipt.id" v-show="ipt.show">
          <div  >
            <div class="m-input-label m-border-start">  {{i18n.age}} </div>
          </div>
          <input class="mform-control" @change="setchild"  type="number" min="0" :max="maxage" autocomplete="off" :id="`ipt`+ipt.id"  v-model="ipt.age">
          <span class="m-btn-sm" @click="shownext(ipt.id)"><b>+</b></span>
          <span class="m-btn-sm" @click="hideinput(ipt.id)"><b>-</b></span>
        </div>
        <div style="display: flex; height: 2.25rem; margin-top: 2px; " >
          <span class="m-btn-sm m-border-start" @click="resetages" > ✗  </span>
          <label @click="dropdowntoggle" class="m-input-label" style="flex-grow: 2; text-align: center; "  > {{ i18n.children }} : {{chcounr}} </label>
          <span class="m-btn-sm m-border-end" @click="dropdowntoggle" > 🗸 </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    export default {
        name: 'ChildAge',
        props: ['lang','i18n' ],
        data: function () {
            return {
                isvisible:false,
                ages:[],
                ageforms: [{id: 0, show: true, age: 0}, {id: 1, show: false, age: 0},{id: 2, show: false,age: 0}, {id: 3, show: false, age: 0}],
                extraclass:null,
                mstyle:{},
            }
        },
        emits: ['m-set-child'],
        methods: {
            dropdowntoggle(){
                this.isvisible = !this.isvisible;
                if(!this.isvisible)
                {
                    this.$emit('m-set-child',this.ages );
                }

                if(window.innerWidth>828){

                    this.mstyle.width = '282px';
                    let shiftY = window.innerHeight - this.$refs.mdbtn.getBoundingClientRect().bottom,
                        shiftX = window.innerWidth - this.$refs.mdbtn.getBoundingClientRect().right,
                        transX = '0', transY='0',left='0' ;
                    if(shiftY<222)
                        transY = 'calc( -1 * ( 106% + 2em ) )';
                    if(shiftX<266)
                    {
                        transX = '-100%';
                        left = '100%';
                    }
                    this.mstyle.transform = 'translate('+transX+','+transY+')';
                    this.mstyle.left = left;
                    this.mstyle.opacity ='1';
                    this.mstyle.pointerEvents = 'auto';
                    this.mstyle.zIndex='1234';

                } else  {
                    this.extraclass='m2mobile';
                    this.mstyle.left = '50%';
                    this.mstyle.transform = 'translateX(-50%)';
                    this.mstyle.top = '3rem';
                    this.mstyle.pointerEvents = 'auto';
                    this.mstyle.width = '90%';
                }

            },
            setchild: function () {
                this.ages = [];
                this.ageforms.forEach(
                    a=>{
                        if(a.age>0)
                            this.ages.push(a.age);
                    }
                );
            },
            shownext(idx) {
                this.setchild();
                if( this.ages[ idx ] > 0)
                    this.ageforms.filter(t => { if(t.id== idx+1) t.show=true; return t; }   );
            },
            hideinput(idx) {
                if (idx > 0) {
                    this.ageforms.filter(t => {
                        if (t.id == idx) {
                            t.show = false;
                            t.age = 0;
                        }
                        return t;
                    });
                } else {
                    let islast = 0;
                    this.ageforms.forEach(
                        a => {
                            if (a.show)
                                islast++;
                        }
                    );
                    if (islast < 2){
                        this.ageforms.filter(t => {
                            if (t.id == idx) {
                                t.age = 0;
                            }
                            return t;
                        });
                        this.ages = [];
                        this.dropdowntoggle();
                    }
                }
                this.setchild();
            },
            resetages(){
                this.ages = [];
                this.ageforms.filter(t => {
                    t.age = 0;
                    if (t.id >0) {
                        t.show = false;
                    }
                    return t;
                });
                this.dropdowntoggle();
            }
        },
        computed: {
            chcounr(){
                return this.ages.length ;
            },
            maxage(){
                return this.$store.state.childage;
            }
        },
    }
</script>
<style scoped>

  .mform-select {
    display: block;
    width: 100%;
    padding: 0.2em 1.8em 0.2rem 0.5em;
    font-size: larger;
    line-height: 1.5;
    color: var(--m-color-dark, #212529);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), linear-gradient(to bottom, var(--m-color-bg2,silver), var(--m-color-bg1,white));
    background-repeat: no-repeat;
    background-position: right 0.2em center, left top;
    background-size: 16px 12px, 100%;
    border: 1px solid var(--m-color-border,silver);
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    min-width: 3em;
    font-family: var(--m-body-font-family,inherit);
  }
  .mform-select:hover , .mform-select:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), linear-gradient(to top, var(--m-color-bg2,silver), var(--m-color-bg1,white));
  }
  .m-input-grp {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  .m-input-grp .m-input-label:first-child not :only-child {
    border-radius: .25rem 0 0 .25rem;
  }

  .m-input-grp > .m-btn-sm:first-child {
    border-radius: .25rem 0 0 .25rem;
  }

  .m-input-grp > .m-input-label:last-child {
    border-radius: 0 .25rem .25rem 0;
  }
  .m-input-grp > .m-btn-sm:last-child {
    border-radius: 0 .25rem .25rem 0;
  }
  .m-input-grp > .mform-control:last-child {
    border-radius: 0 .25rem .25rem 0;
  }

  .m-input-label {
    text-align: right;
    padding: 0 .2em;
    margin: 0 ;
    white-space: nowrap;
    display: grid;
    align-items: center;
    border: 1px solid var(--m-color-border);
    background-image: linear-gradient(to bottom, var(--m-color-bg2,silver), var(--m-color-bg1,white));
    height: 100%;
  }
  .mform-control {
    display: block;
    width: 100%;
    padding: 0.375rem .75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--m-color-dark);
    border: 1px solid var(--m-color-border);
    background-color: var(--m-color-bg0,initial);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }


  .m-btn-sm {
    display: flex;
    align-items: center;
    min-width: 2em;
    justify-content: center;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: 1px var(--m-color-border,silver) solid;
    padding: 0 .5em;
    background-image: linear-gradient(to bottom, var(--m-color-bg2,silver), var(--m-color-bg1,white));
  }
  .m-btn-sm:hover , .m-btn-sm:focus {
    background-image: linear-gradient(to top, var(--m-color-bg2,silver), var(--m-color-bg1,white));
    border: 1px var(--m-color-select,darkgreen) solid;
  }
  .m-input-grp > .m-btn-sm:first-child {
    border-radius: .25rem 0 0 .25rem;
  }
  .m-input-grp > .m-btn-sm:last-child {
    border-radius: 0 .25rem .25rem 0;
  }


</style>