import { createApp } from 'vue'
import App from './App.vue'
import store from './store'


const mountEl = document.querySelector('#booker3a');
if(mountEl) {
    createApp(  App, { ...mountEl.dataset })
        .use(store)
        .mount('#booker3a');

}
//createApp(App).mount('#app')
