<template>
<div v-show="!isdone"> <div class="m-spinner-bar" ></div> </div>
  <div  v-show="isdone"  >
    <div class="m-warn-block" v-if="warns.master" > {{ warns.master }}</div>
<div v-else class="m-main-block" >
  <search-form ref="c2from" :hid="hid" :i18n="i18n" :curl="hostedon" :room="room" :lang="lang"  ></search-form>
  <modal-guest ref="g2from" :hid="hid" :i18n="i18n" :curl="hostedon" :lang="lang"></modal-guest>
</div>

    <booking-cart v-if="cartexists" :cart="$store.getters.CartData" :curl="hostedon" :i18n="i18n" :lang="lang"
                  :hid="hid"  @cart-proceed="cardproceed" @cart-updating="cartprocessing"  >  </booking-cart>

  </div>

<div v-if="warns.booked_ok" class="m-cart-footer m-border-radius"  >
<h4 class="m-dialog__title">
    <span  > {{warns.booked_ok.hdh}} </span>
    <span  @click="warns.booked_ok=null" > 🗙 </span>
</h4>
<div v-html="warns.booked_ok.txt" style="padding: .2em 1em; "></div>
</div>
<div v-if="warns.booked_fail" class="m-warn-block" @click="warns.booked_fail=null">{{warns.booked_fail}}</div>

</template>
<script>
import axios from 'axios'
import SearchForm from './components/SearchForm.vue'
import ModalGuest from './components/ModalGuest'
import BookingCart from './components/BookingCart'
export default {
  name: 'Booker',
  components: {
      SearchForm,ModalGuest, BookingCart
  },
    props: {
        hid:{ type: String, default:'0'},
        room:{ type: String, default:'0'},
        lang:{type:String,default:'en'},
    },
    data() {
        return {
            i18n : [],
//            curl : "http://127.0.0.1:8000",
//        curl:"https://hotelwinds.com",
//          curl:"http://demo.hotelwinds.com",
            isdone : false,

            warns:{  },
        }
    },

    methods: {
        masterinit(){
            this.$store.commit('SET_CURL',this.hostedon);
            let idata = {
                hid:this.hid,
                lang:this.lang,
                session:localStorage.getItem('session'),
            };


            axios.get(this.hostedon +'/bapi/init', {params: idata })
                .then(response => {
                    if(response.data.status>0){
                        this.warns.master =  response.data.message ;
                    } else {
                        this.i18n = response.data.i18n;
                        localStorage.setItem('session', response.data.session);

                        this.$store.commit("SET_AGE",response.data.childage );
                        this.$store.dispatch("assirgCurrencis", response.data.curlist );

                        let storedCurrency = localStorage.getItem('currency');

                        if(storedCurrency)  this.$store.dispatch("initDefCurrency", storedCurrency);
                       // console.log(' refperson',  response.data.refperson );
                        this.$store.commit("SET_REFPERSON", response.data.refperson );
                        // это потом переделаем
                        console.log('cart ID from master init ',response.data.cart.id );
                        if(response.data.cart.id){
                            this.$store.commit('SET_CART',response.data.cart);
                            this.$store.commit('SET_DEFCUR',{id:response.data.cart.currency ,sym: response.data.cart.currency_sym} );
                            this.$refs.c2from.dfrom = response.data.cart.dfrom;
                            this.$refs.c2from.dtill = response.data.cart.dtill;
                            this.$refs.c2from.fromdatelim = response.data.cart.dfrom;
                        }
                    }
                })
                .catch(error => {
                    console.log('M-ERROR: ',error.toString() );
                    this.warns.master = error.toString() ;
                })
                .then(() => {
                    this.isdone=true;
                    console.log('Master init done' );
                });
        },
        cardproceed(refp){
            let ncart = {
                id:this.$store.getters.CartId,
                refperson : {
                    email:refp.email,
                    tel: refp.tel,
                    fn: refp.fname,
                    sn: refp.sname,
                }
            };

            let data = {
                    hid: this.hid,
                    cart: ncart,
                    session:localStorage.getItem('session'),
                };
            axios.post( this.hostedon +'/bapi/cart/proceed',data)
                .then(response => {
                    if(response.data.status>0){
                        this.warns.booked_fail = response.data.message;
                    } else {
                        this.$store.commit('SET_CART',{id:null});
                        this.warns.booked_ok =response.data.txt;
                    }
                })
                .catch(error => {
                    this.warns.booked_fail = error.toString();
                })
                .then(() => {
                   document.getElementById('booker3a').scrollIntoView();
                });
        },
       cartprocessing(){
           this.$refs.c2from.rfound = [];
       }

    },
    computed: {
      cartexists(){
            return  this.$store.getters.CartId ;
        },
      refperson(){
          return this.$store.getters.refPerson;
        },
      hostedon(){
          if(location.hostname == '127.0.0.1')
              return  "http://127.0.0.1:8000" ;
          if(location.hostname == 'demo.hotelwinds.com')
              return "https://demo.hotelwinds.com" ;
          return  "https://hotelwinds.com";
      }
    },
    created() {
        this.masterinit();
    },
    mounted() {
        console.log('APP mounted on: ',location.hostname , this.hostedon , 'hotel: ',this.hid, ' room:', this.room );
    }

}
</script>

<style>
  *, ::after, ::before {
    box-sizing: border-box;
  }
  @font-face {
    font-family: "color-emoji";
    src: local("Apple Color Emoji"), local("Segoe UI Emoji"), local("Segoe UI Symbol"), local("Noto Color Emoji");
  }
  :root {
    --m-color-dark: black;
    --m-color-light:white;
    --m-color-bg0: #fafbfc;
    --m-color-bg1: #f2f2f2;
    --m-color-bg2: #a9a9a9;
    --m-color-border: #c0c0c0;

    --m-color-today:blue;
    --m-color-select:green;
    --m-color-alert:orangered;
    --m-color-success:#00cc00;
    --m-color-info:#bf00ff;

    --m-border-rad: 0.35em;
    --m-txt-align: justify;
/* это опция, сам шрифт подходит не очень */
    --m-body-font-family:  'Montserrat', sans-serif;


  }


@import "assets/modal.css";
@import "assets/searchform.css";
@import "assets/main.css";
@import "assets/checkbox.css";
</style>
