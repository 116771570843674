<template>
  <div class="m-slider-div" :style="{ 'background-image': 'url('+limg+')' }" >
    <h2 @click="mshowModal">{{title}}</h2>
  </div>
  <dialog ref="mdialog" class="m-dialog-slider"  >
    <div style="display: flex;   flex-direction: column; height: 100%;">
      <h4 class="m-dialog__title"><span> {{title}} </span><span @click="mClose" >🗙</span></h4>
      <div  class="m-dialog__content">
        <div class="m-slider-max" :style="{ 'background-image': 'url('+limg+')' }">
          <button><span class="m-ico" @click="shiftMimg(-1)"> ◀ </span> </button>
          <button><span class="m-ico" @click="shiftMimg(1)"> ▶ </span></button>
        </div>
        <div class="m-slider-ico">
          <div class="m-img-small" v-for="(mimg,idx) in ilist" @click="mpos=idx" :class="getAclass(idx)">
            <img alt="img"  :src="mimg.uri" />
          </div>
        </div>
        <div class="m-dialog__footer">
          <button class="m-btn__dismiss" @click="mClose" >close</button>
        </div>
      </div>
    </div>
  </dialog>
</template>
<script>
  export default {
      name:'ModalDialog',
//      props:['title','itxt','btnclass','btnlabel'],
      props: {
          title: {type: String, required: false, default:'dialog title'},
          ilist: {type: Array,required: true,default: []},
      },
      data: function () {
          return {
              limg:'',
              mpos:0,
          }
      },
      methods: {
          mshowModal(){
           this.$refs.mdialog.showModal();
          },
          mClose() {
              this.mpos = 0;
              this.$refs.mdialog.close();
          },
          shiftMimg(sh){
              let newpos = this.mpos+=sh ;
              if(newpos<0){
                  this.mpos = this.sizelist -1;
                  return true;
              }

              if(newpos == this.sizelist)
              {
                  this.mpos = 0;
                  return  true;
              }
              this.mpos = newpos;
          },
          getAclass(mpos){
              if(mpos == this.mpos)
                  return 'm-img-active';
              return null;
          }
      },
      computed: {
          sizelist(){
            return this.ilist.length ;
          },
      },
      watch: {
        mpos: function () {
            this.limg = this.ilist[this.mpos].uri;
        },
      },
      mounted() {
          this.limg = this.ilist[this.mpos].uri;
      }

  }
</script>
<style>
</style>