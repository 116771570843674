const rmFromStorage =function(prefix,pl) {
let arr = [];
    for (let i = 0; i < localStorage.length; i++){
        if (localStorage.key(i).substring(0,pl) == prefix.substring(0,pl) ) {
            arr.push(localStorage.key(i));
        }
    }
    for (let i = 0; i < arr.length; i++) {

        localStorage.removeItem(arr[i]);
    }
};

export default rmFromStorage ;