<template>
  <button v-if="ibtnshow" :class="ibtncls" @click="mshowModal(null,null)">{{ ibtnlabel }}</button>
  <dialog ref="mdialog" class="m-dialog">
    <h4 class="m-dialog__title"><span> {{ ititle }} </span><span @click="mClose" >🗙</span></h4>

    <div v-if="loading" class="m-dialog__content">
      <div class="m-loader"></div>
    </div>
    <div v-else class="m-dialog__content">
      <div class="m-warn-block" v-if="warns.master"> {{ warns.master }}</div>
     <div v-html="mhtml" ></div>

    <div class="m-dialog__footer">
       <button class="m-btn__dismiss" @click="mClose">close</button>
       <button v-if="confdialog" class="m-btn__confirm" @click="mConfirm"> {{conftxt}} </button>
</div>
</div>
</dialog>
</template>
<script>
  import axios from 'axios'
  import rmFromStorege from '../tools/rmFromStorage'

  export default {
      name:'ModalDialog',
//      props:['title','itxt','btnclass','btnlabel'],
      props: {
          ititle: { type:String, required:false,default: 'Dialog title' },
          icontent: { type:String, required:false,default: 'Lorem ipsum' },

          ibtnshow: {type:Boolean,required:false,default:true},
          ibtncls: {type:String,required:false,default: 'm-btn-vlist'},
          ibtnlabel:{type:String,required:false,default:'open dialog'},

          extraload:{type:Boolean,required:false,default:false },
          extraurl:{type:String,required:false,default:null},
          extrapar:{type:Object,required:false,default:{}},

          confdialog:{type:Boolean,required:false,default:false},
          conftxt:{ type:String, required:false,default: 'confirm' },

      },
      data: function () {
          return {
              loading:false,
              mhtml:'',
              warns:{},
              curl:'',
              rqprms:{},
          }
      },
      emits: ['m-confirm'],
      methods: {
          mshowModal(icurl,ipar){
              if(this.extraload ){
                  if(icurl){
                      this.curl = icurl;
                      if(ipar) this.rqprms = ipar;
                  } else {
                      if(this.extraurl )   this.curl =  this.extrapar;
                      if(this.extrapar)  this.rqprms = this.extrapar;
                  }

                  let stored = localStorage.getItem('modcont'+this.rqprms.id);
                  if(stored)
                    this.mhtml = stored;
                  else
                    this.getContent();
              } else {
                  this.mhtml = this.icontent;
              }
           this.$refs.mdialog.showModal();

          },
          mClose() {
              this.mhtml='';
              this.$refs.mdialog.close();
          },
          mConfirm(){
              this.$emit('m-confirm');
              this.mClose();
          },
          getContent(){
              this.loading=true;
              this.rqprms.session = localStorage.getItem('session');

              axios.get( this.curl , {params: this.rqprms })
                  .then(response => {
                      if(response.data.status>0){
                          this.warns.master =  response.data.message ;
                      } else {
                          this.mhtml = response.data.html;
                          // надо добавить запись в storage что бы два раза не отправлять запрос
                          localStorage.setItem('modcont'+this.rqprms.id, this.mhtml );
                      }
                  })
                  .catch(error => {
                      console.log('M-ERROR: ',error.toString() );
                      this.warns.master = error.toString() ;
                  })
                  .then(() => {
                      this.loading=false;
                  });
          }
      },
      mounted() {
           rmFromStorege('modcont',6);
      },

  }
</script>
<style>

</style>