<template>
  <div class="m2dropdown"  >
      <button :class="btnclass" ref="mdbtn" type="button"   @click="dropdowntoggle" :title="btntit">
        <span v-html="btnlabel">  </span>
      </button>
    <div v-show="isvisible" :class="extraclass"   >
      <div class="m2drop-menu"  :style="mstyle"  >
        <div  v-if="stitle">
          <div class="m-alert-block"  v-html="mtxt">          </div>
          <div class="m-btn-group">
            <button class="m-btn__confirm" @click="mConfirm"><span class="m-ico">✔</span> </button>
            <button class="m-btn__dismiss" @click="hidedrop"><span class="m-ico">❌</span></button>
          </div>

        </div>

        <div style="display: flex; flex-direction: column;" v-for="i in items" :key="i.id" @click="dropdowntoggle">
          <slot name="dropdownitem" :item="i">  </slot>
        </div>

      </div>
    </div>
</div>

</template>
<script>
  export default {
      name: 'MdropDown',
      props: {
          msize:{type: Number, required: false, default: 242 },
          items: { type: Array, required: true } ,
          btnlabel: {type: String, required: false, default:'✅'},
          btnclass: {type:String,required:false, default:'m-drop-select'},
          btntit: {type:String,required:false, default:'Title'},
          mtxt: {type:String,required:false,default:''},
          },
      data: function () {
        return {
          isvisible:false,
          extraclass:null,
          mstyle:{ },
        }
      },
      emits: ['m-confirm'],
      methods: {
          dropdowntoggle(){
              if(this.isvisible) {
                  this.hidedrop();
                  return true;
              }
              this.isvisible = true;

              if(!this.$refs.mdbtn)
                  return false;
              if(window.innerWidth>828){

                  this.mstyle.width = this.msize+'px';
                  let shiftY = Math.round( window.innerHeight - this.$refs.mdbtn.getBoundingClientRect().bottom),
                      shiftX = Math.round(window.innerWidth - this.$refs.mdbtn.getBoundingClientRect().right),
                  transX = '0', transY='0',left='0' ;
                  if(shiftY<222)
                      transY = '-124%';
                  if(shiftX<345)
                  {
                      transX = '-100%';
                      left = '100%';
                  }

                  this.mstyle.transform = 'translate('+transX+','+transY+')';
                  this.mstyle.left = left;
                  this.mstyle.opacity ='1';
                  this.mstyle.pointerEvents = 'auto';
                  this.mstyle.zIndex='1234';
                  //console.log('shift X: ', shiftX,' shift Y: ',shiftY , this.mstyle.transform );
              } else  {
                  this.extraclass='m2mobile';
                  this.mstyle.left = '50%';
                  this.mstyle.transform = 'translateX(-50%)';
                  this.mstyle.top = '3rem';
                  this.mstyle.pointerEvents = 'auto';
                  this.mstyle.width = '90%';
              }

          },
          hidedrop(){
              this.mstyle= {};
              this.extraclass=null;
              this.isvisible=false;
          },
          mConfirm() {
              this.hidedrop();
              this.$emit('m-confirm');
          }

      },
      computed: {
          stitle(){
              if(this.mtxt.length>1)                  return  true;
              return false;
          }
      },

  }
</script>
<style>
.m-alert-block {
  text-align: var(--m-txt-align,justify);
  padding: .2em .4em;
  font-size: larger;
  margin-bottom: .4em;
}

</style>