<template>
  <div style="position: relative; width: 100%; height: 100%;  ">
    <button type="button" class="m-dp-button" :style="btncss"  ref="mdbtn" @click="mtoggle">{{formatteddate}}</button>
    <div :class="dropclass"  v-show="showcont" @click="hidedrop">
      <div class="m-dp-content" @click.stop :style="mstyle"   >
        <div class="m-dp-month">
          <div class="m-dp-month-prev">
            <button type="button" v-if="allowpast"  class="m-btn-block-act" @click="chmonth(-1)"> ◀ </button>
            <button type="button" style="opacity: 0.6;" v-else  > ◀ </button>
          </div>
          <div class="m-dp-month-this">{{showmonth}} {{cyear}}</div>
          <div class="m-dp-month-next">
            <button v-if="allowfuture" type="button" class="m-btn-block-act" @click="chmonth(1)"> ▶ </button>
            <button type="button" style="opacity: 0.6;" v-else  > ▶ </button>
          </div>
        </div>
        <div class="m-dp-days">
          <div class="mdow" v-for="(d,idx) in getDoWlist" :key="idx">{{d}}</div>
          <div v-for="ms in mdaylist" :key="ms.mday" :class="ms.mcss" style="border-radius: .3rem; margin: 1px;">
            <span v-if="ms.a" @click="selectdate(ms.mday)">{{ms.d}}</span>
            <span v-else>{{ms.d}}</span>
          </div>
        </div>
        <div style="display: flex; justify-content: center; ">
          <button type="button" class="m-btn-block" @click="mtoggle" > 🗙 </button>
        </div>
      </div>
    </div>
  </div>
<input type="hidden" v-model="cday" :name="mname">
</template>
<script>
//   ./node_modules/.bin/vue-cli-service build  --modern --target lib --name DatePicker src/components/DatePicker.vue
    import moment from 'moment'

    export default {
        name: 'DatePicker',
        props: ['mstart','lang','mtlim','mflim','mreadonly','mname'],
        data: function () {
            return {
                showcont:false,
                minday:null, // это минимальная дата
                maxday:null, // это максимальная дата
                cday:null, // это выбранная дата
                cmon:null, // это позиция курсора
                cyear:null, // это позиция курсора
                fdate:null, // это позиция курсора
                mdaylist:[],
                mtday:null,
                mtday2:'',
                mstyle:{  transform:'',width:'360px'  },
                dropclass:null,
            }
        },
        emits: ['md-selected'],

        methods: {
            hidedrop(){
                this.showcont = false;
            },
            selectdate(mday){
                this.cday = mday;
                this.$emit('md-selected',this.cday);
                this.showcont = false;
            },

            chmonth(shift){
                let newmonth = this.cmon + shift;
                if(newmonth>12)
                {
                    newmonth=1;
                    this.cyear = this.cyear+1;
                }
                if(newmonth<1)
                {
                    newmonth=12;
                    this.cyear = this.cyear-1;
                }
                this.cmon = newmonth;
                let str = this.cyear+'-'+ this.cmon+'-01';
                this.fdate = moment(str);
                this.getdaylist();

            },
            mtoggle(){

                if(this.mreadonly)
                    return true;

                if(this.showcont)
                {
                    this.showcont = false;
                    return  true;
                }
                this.dropclass=null;


                if(  window.innerWidth  > 800){
                    let shiftY = window.innerHeight - this.$refs.mdbtn.getBoundingClientRect().bottom,
//                        shiftX = window.innerWidth - this.$refs.mdbtn.getBoundingClientRect().right,
                        shiftX = parseInt( this.$refs.mdbtn.getBoundingClientRect().left ),
                    transX = '0', transY='0',left='0' ;
                    if(shiftY<200)
                    {
                        transY = '-100%';
                        this.mstyle.top = 0;
                    } else {
                       transY = 0;
                       this.mstyle.top = parseInt(this.$refs.mdbtn.getBoundingClientRect().bottom - this.$refs.mdbtn.getBoundingClientRect().top )  +'px';
                    }

                    if(shiftX>170){
                        this.mstyle.right = left;
                    }
                    this.mstyle.transform = 'translate('+transX+','+transY+')';
                }
                else {
                    this.dropclass='m2mobile';
                    this.mstyle.top = '3rem';
                    this.mstyle.pointerEvents = 'auto';
                    this.mstyle.width = '100vw';
                }

                this.showcont =  true;
            },
            init1() {
                if ( moment( this.mflim, ["YYYY-MM-DD"], true).isValid() ) {
                    this.minday = moment(this.mflim);
                }
                else                this.minday =  moment( ).startOf('year');

                if( moment( this.mtlim, ["YYYY-MM-DD"], true).isValid() )
                  this.maxday = moment( this.mtlim) ;

                if( moment( this.mstart, ["YYYY-MM-DD"], true).isValid() )
                  this.fdate = moment( this.mstart );
                else
                    this.fdate = moment( );
                this.cday = this.mstart;
                this.fdate.locale(this.lang);
                this.cyear = parseInt(this.fdate.format('YYYY'));
                this.cmon = parseInt(this.fdate.format('M') );
                //console.log('init ', this.minday.format('YYYY M DD'), this.maxday.format('YYYY M DD')  );
                this.getdaylist();
            },

            getdaylist(){
                let mstart = this.fdate.clone().startOf('month') ;
                const month_start = parseInt(mstart.format('MM'));
                mstart.isoWeekday(1);
                let mend = this.fdate.clone().endOf('month') ;
                mend.isoWeekday(7);
                const month_end =  parseInt(mend.format('MM')) ;
                this.mdaylist=[];
                //console.log('get days list ', this.mname,  this.cday);
                while (mstart<mend){
                    let css = ['m-cell' ];
                    if(  this.mtday2==mstart.format('YYYY-MM-DD') ){
                        css.push( 'm-cell-td');
                    }
                    if(  this.cday==mstart.format('YYYY-MM-DD') ){
                        css.push('m-cell-selected');
                    }
                    let cmonth = parseInt(mstart.format('MM')) ;
                    if( cmonth == month_end ||  cmonth !== month_start  ){
                        css.push('m-cell-out');
                    } else
                        css.push('m-cell-in');


                    let allowselect = true;

                    if( mstart<this.minday ){
                        css.push('m-cell-off');
                        allowselect = false;
                    }

                    if(this.maxday &&  mstart>this.maxday )
                    {
                        css.push('m-cell-off');
                        allowselect = false;
                    }

                    this.mdaylist.push(
                        {
                            "mday": mstart.format('YYYY-MM-DD'),
                            "d": mstart.format('DD'),
                            "mcss":css,
                            'a':allowselect,
                        });
                    mstart.add(1,'day');
                }
                return true;
            },

        },
        watch: {
            mtlim: function () {
                this.init1();
            },
            mflim: function () {
                this.init1();
            },
            cday: function () {
                this.getdaylist();
            }
        },
        computed: {
            btncss(){
                if( this.mreadonly )
                    return { cursor: 'default' };
                return { cursor: 'pointer' }
            },
            showmonth(){
                if(this.fdate){
                    return  this.fdate.format('MMMM');
                }
                return 'select';
            },
            getDoWlist()
            {
                moment.locale(this.lang);
                let dow = moment.weekdaysShort();
                dow.push(dow.shift());
                return  dow;
            },
            allowpast(){
                if(this.minday && this.cmon ){
                    if( this.minday.format('YYYY') < this.cyear )
                        return  true;

                    return  this.minday.format('M') <  this.cmon  ;
                }
                //else                    console.log('allowpast(): (203) данные пока не инициализированы');

                return false;
            },
            allowfuture(){
                if(this.maxday && this.cmon ){
                    if(this.cyear > this.maxday.format('YYYY'))
                        return  false;
                    return this.maxday.format('M') > this.cmon
                }
                return true;
            },
            formatteddate(){
                if(this.lang)                  moment.locale(this.lang);
                let tday = moment(this.cday);
                return  tday.format('ddd LL');
            }
        },
        created(){
            this.mtday = moment();
            this.mtday2 = this.mtday.format('YYYY-MM-DD');
            this.init1();
        },

    }
</script>
<style scoped>
  *, ::after, ::before {
    box-sizing: border-box;
  }
  .m-dp-content {
    display: grid;
    position: absolute;
    overflow: auto;
    z-index: 222;
    border-radius: .4rem;
    color: var(--m-color-dark,#1a1a1a);
    background-color: var(--m-color-light,white);
    box-shadow: inset 0 0 10px  ;
    padding: .5em 1em;
    font-family: var(--m-body-font-family,inherit);
  }

  .m-dp-button {
    background-image: linear-gradient(to bottom, var(--m-color-bg2,#b3b3b3), var(--m-color-bg1,white));
    padding: .2em .4em;
    line-height: 1.5;
    width: 100%;
    height: 100%;
    font-size: larger;
    color: var(--m-color-dark,#1a1a1a);
    border: 1px solid var(--m-color-border,#8c8c8c);
    border-radius: var(--m-border-rad,.3em);
    white-space: nowrap;
    font-family: var(--m-body-font-family,inherit);
  }
  .m-dp-button:hover {
    background-image: linear-gradient(to top, var(--m-color-bg2,#b3b3b3), var(--m-color-bg1,white));
    text-shadow: 1px 1px 0 var(--m-color-light,white);
  }

  .m-dp-month {
    display: grid;
    grid-template-columns: 10% auto 10%;
  }
  .m-dp-month button {
    background-image: linear-gradient(to bottom, var(--m-color-bg2,#b3b3b3), var(--m-color-bg1,white));
    padding: .2em .4em;
    font-size: larger;
    width: 100%;
    height: 100%;
    border: 1px solid var(--m-color-border,#8c8c8c);
  }
  .m-dp-month-prev button {
    border-radius: .25rem 0 0 .25rem;

  }
  .m-dp-month-next button {
    border-radius: 0 .25rem .25rem 0;
  }
  .m-dp-month-this {
    background-image: linear-gradient(to bottom, var(--m-color-bg2,#b3b3b3), var(--m-color-bg1,white));
    display: flex;
    min-width: 2em;
    line-height: 1.5;
    text-decoration: none;
    border: 1px var(--m-color-border,#8c8c8c) solid;
    padding: 0.375rem 0.4rem ;
    font-size: larger;
    justify-content: center;
  }

  .m-dp-days {
    display: grid;
    grid-template-columns: repeat(7,1fr);
    text-align: center;
    font-size: larger;

  }
  .m-dp-days  .mdow {
    border-bottom: 1px solid var(--m-color-border,#8c8c8c);
  }

  .m-cell {
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
  }
  .m-cell-in {
    color: var(--m-color-dark,#1a1a1a);
    border: 1px solid transparent;
    cursor: pointer;
  }
  .m-cell-in:hover {
    border: 1px solid var(--m-color-border,#8c8c8c);
  }
  .m-cell-td {
    color: var(--m-color-today ,  #ff1a75);
    border: 1px var(--m-color-today,#ff1a75) solid;
    cursor: pointer;
  }
  .m-cell-td:hover {
    background-color: var(--m-color-today,  #ff1a75);
    color: var(--m-color-light,white);
    border: 1px solid transparent;
  }
  .m-cell-selected {
    color: var(--m-color-select,#002db3);
    border: 1px var(--m-color-select,#002db3) solid;
    cursor: pointer;
  }
  .m-cell-selected:hover {
    color: var(--m-color-light,white);
    background-color: var(--m-color-select,#002db3);
    border: 1px solid transparent;
  }
  .m-cell-off {
    opacity: 0.5;
    color: var(--m-color-dark,#1a1a1a);
    cursor: not-allowed ;
  }
  .m-cell-out {
    opacity: 0.75;
    color: var(--m-color-dark,#1a1a1a);
    cursor: pointer;
  }
  .m-btn-block {
    width: 100%;
    cursor: pointer;
    background-image: linear-gradient(to bottom, var(--m-color-bg2,#b3b3b3), var(--m-color-bg1,white));
    border: 1px solid var(--m-color-border,#8c8c8c);
    padding: .2em .4em;
    font-size: larger;
    border-radius: .35rem;
  }
  .m-btn-block:hover{
    background-image: linear-gradient(to top, var(--m-color-bg2,#b3b3b3), var(--m-color-bg1,white));
    text-shadow: 1px 1px 0 var(--m-color-light,white);
  }
  .m-btn-block-act {
    cursor: pointer;
  }
  .m-btn-block-act:hover {
    background-image: linear-gradient(to top, var(--m-color-bg2,#b3b3b3), var(--m-color-bg1,white));
  }

</style>